export const msalConfig = {
  auth: {
    clientId: "7e67dbbf-4d03-4df9-bc14-62f3a3225d73",
    //authorit: "https://guycob2c.b2clogin.com/guycob2c.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN",
    authority: "https://guycob2c.b2clogin.com/guycob2c.onmicrosoft.com/B2C_1_SIGNUPSIGNIN_Userflow",
    knownAuthorities: ["guycob2c.b2clogin.com"],
    redirectUri: process.env.PUBLIC_URL,
    validateAuthority: false,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["https://guycob2c.onmicrosoft.com/api-2/access_as_user"],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
